const isLog = true
const appDefaultLocale = 'en'

// const mainProductCode = 'shareearn'
// const appName = 'shareearn.net'
// const localApiHost = 'https://shareearn.net'

const mainProductCode = 'phpshare'
const appName = 'phpshare.com'
const localApiHost = 'https://phpshare.com'

const sessionLocale = 'm_shareearn_locale'
const sessionFrom = 'm_shareearn_from'
const sessionParentid = 'm_shareearn_parentid'
const sessionDisplayid = 'm_shareearn_displayid'
const sessionEmail = 'm_shareearn_email'
const sessionFinger = 'm_shareearn_finger'
const sessionAppid = 'm_shareearn_appid'

const mainScheme = 'https'
const mainEntrance = ''
const mainHost = ''
const mainApiHost = ''
const mainStaticHost = ''
const encryptKey = '58906be6bfd84405898bea02a04cc504v1001'

const loginType = 1
const registerType = 2

const appid = '0'
const displayid= '0'
const email = ''
const mainFringer = ''
const parentid = 0
const from = ''
const isLogin = false
const coin = ''
const basecoin = ''
const pointNum = 2
const ip = ''
const ipdetail = ''
const ipnum = 0

const gameTab = 0
const cq9Tab = 0
const walletType = 0

const pupdogeGameTab = 0
const pupdogeWalletType = 0
const cqmspk10Type = 0

const faseshowad1 = true

const telegramAccount = '@ShareEarnNet'
const whatsappAccount = ''
const emailAccount = 'contact@shareearn.net'

export default {
	isLog,
	appName,
	appDefaultLocale,
	localApiHost,
	
	sessionLocale,
	sessionFrom,
	sessionParentid,
	sessionDisplayid,
	sessionEmail,
	sessionFinger,
	sessionAppid,

	mainProductCode,
	mainScheme,
	mainEntrance,
	mainHost,
	mainApiHost,
	mainStaticHost,
	encryptKey,
	
	loginType,
	registerType,
	
	appid,
	displayid,
	email,
	mainFringer,
	parentid,
	from,
	isLogin,
	coin,
	basecoin,
	pointNum,
	ip,
	ipdetail,
	ipnum,
	
	gameTab,
	cq9Tab,
	walletType,
	
	pupdogeGameTab,
	pupdogeWalletType,
	cqmspk10Type,
	
	telegramAccount,
	whatsappAccount,
	emailAccount,
	
	faseshowad1,
}